class utilitiesFunctions {

    test() {
        alert('utilities: ci sono');
    }

    //Converto stringa in array
    string_to_array(str) {
        if (!str == '') {
            return str.trim().split(',');
        } else {
            return [str];
        }
    };

    //Rempove duplicate form array
    uniq(arr) {
        var result = [];
        $.each(arr, function (i, e) {
            if ($.inArray(e, result) == -1) result.push(e);
        });
        return result;

    }


    /*
     ******************************************************************************************************************************************************
     *
     * Ajax Post
     *
     ******************************************************************************************************************************************************
     */
    /*
    Parameters
    uf = utilitiesFunctions
    pf = postSuccessFunctions
    postParams = Parametri per la chiamata ajax
    ac = (opz) autocompleteFunctions
    te = (opz) textEditorFunctions
    */
    ajaxPost(uf, pf, postParams, ac = null, te = null) {

        var showLoading = true;

        if (showLoading) {
            uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
        }


        //console.log(postParams);
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: postParams.type,
            url: postParams.uri,
            data: postParams.data,
            success: function (response) {

                //console.log(response);

                //Controllo se devo aprire una modale o fare chiamata ajax standard
                if (postParams.ismodal) {

                    var modalParams = {
                        'modalId': response.modalId,
                        'modalTitle': response.modalTitle,
                        'params': postParams.params,
                    }

                    uf.showModal(response, uf, modalParams);
                } else {
                    //Chiamo dinamicamente la funzione di successo
                    pf.executeSuccess(postParams.onSuccess, response, uf, ac, te);
                };

                if (showLoading) {
                    uf.loaderStop();
                };

            },
            error: function (e, textStatus, errorThrown) {
                //console.log('AJAX error: ' + textStatus + ' : ' + errorThrown);
                //console.log('Errore...\n' + JSON.stringify(e));

                var err = JSON.parse(e.responseText);
                console.log(err);

                uf.loaderStop();

                //Chiudo il loader in caso di errore
                uf.loader('sk-cube-grid', '<h5>Attenzione, si è verifcato un errore nella richiesta...</h5>' + err.message + '<br>in ' + err.file + ' riga ' + err.line);
                setTimeout(function () {
                    uf.loaderStop();
                }, 5000);
            }
        });
    }


    /*
     ******************************************************************************************************************************************************
     *
     * Loader
     *
     ******************************************************************************************************************************************************
     */

    loader(theme, message) {
        HoldOn.open({
            //theme: 'sk-cube-grid',
            //message: '<h4>Loading content</h4>'
            theme: theme,
            message: message,

        })
    }

    loaderStop() {
        HoldOn.close();
    }

    /*
     ******************************************************************************************************************************************************
     *
     * Tooltip
     *
     ******************************************************************************************************************************************************
     */
    loadTooltip() {
        $('[data-toggle="tooltip"]').tooltip();
    }


    /*
     ******************************************************************************************************************************************************
     *
     * Colorpicker
     *
     ******************************************************************************************************************************************************
     */
    colorPicker(itemContainer, item, bgContainer, saveText, clearText) {
        if ($('.' + itemContainer).length) {
            const pickr = new Pickr({

                // Selector or element which will be replaced with the actual color-picker.
                // Can be a HTMLElement.
                el: '.' + itemContainer,

                // Using the 'el' Element as button, won't replace it with the pickr-button.
                // If true, appendToBody will also be automatically true.
                useAsButton: true,

                // Start state. If true 'disabled' will be added to the button's classlist.
                disabled: false,

                // If set to false it would directly apply the selected color on the button and preview.
                comparison: true,

                // Default color
                default: $('.' + item).val(), //'fff',

                // Optional color swatches. null by default which means it's disabled.
                // Types are all these allowed which can be used in pickr e.g. hex, hsv(a), hsl(a), rgb(a) and cmyk
                swatches: null,

                // Default color representation.
                // Valid options are `HEX`, `RGBA`, `HSVA`, `HSLA` and `CMYK`.
                defaultRepresentation: 'HEX',

                // Option to keep the color picker always visible. You can still hide / show it via
                // 'pickr.hide()' and 'pickr.show()'. The save button keeps his functionality, so if
                // you click it, it will fire the onSave event.
                showAlways: false,

                // Defines a parent for pickr, if useAsButton is true and a parent is NOT defined
                // 'body' will be used as fallback.
                parent: null,

                // Close pickr with this specific key.
                // Default is 'Escape'. Can be the event key or code.
                closeWithKey: 'Escape',

                // Defines the position of the color-picker. Available options are
                // top, left and middle relativ to the picker button.
                // If clipping occurs, the color picker will automatically choose his position.
                position: 'top',

                // Enables the ability to change numbers in an input field with the scroll-wheel.
                // To use it set the cursor on a position where a number is and scroll, use ctrl to make steps of five
                adjustableNumbers: true,

                // Show or hide specific components.
                // By default only the palette (and the save button) is visible.
                components: {

                    preview: true, // Left side color comparison
                    opacity: false, // Opacity slider
                    hue: true, // Hue slider

                    // Bottom interaction bar, theoretically you could use 'true' as propery.
                    // But this would also hide the save-button.
                    interaction: {
                        hex: false, // hex option  (hexadecimal representation of the rgba value)
                        rgba: false, // rgba option (red green blue and alpha)
                        hsla: false, // hsla option (hue saturation lightness and alpha)
                        hsva: false, // hsva option (hue saturation value and alpha)
                        cmyk: false, // cmyk option (cyan mangenta yellow key )

                        input: true, // input / output element
                        clear: true, // Clear button
                        save: true // Save button
                    },
                },

                // Button strings, brings the possibility to use a language other than English.
                strings: {
                    save: saveText, // Default for save button
                    clear: clearText, // Default for clear button
                }
            });

            //Events
            pickr.on('save', cb => {
                var selectedColor = cb.toHEXA().toString()
                $('.' + item).val(selectedColor);
                $('.' + bgContainer).css('background-color', selectedColor);
                //console.log(cb);
            });

        }
    }

    /*
     ******************************************************************************************************************************************************
     *
     * Alert
     *
     ******************************************************************************************************************************************************
     */
    alertBox(alertParams) {
        $.alert({
            theme: 'material', // 'material', 'bootstrap'
            type: 'orange',
            icon: 'fas fa-exclamation-triangle',
            //animation: 'top',
            title: alertParams.title,
            content: alertParams.message,
            buttons: {
                confirm: {
                    text: '<i class="fas fa-thumbs-up"></i> ' + alertParams.btnConfirm,
                    btnClass: 'btn btn-success',
                    action: function () {}
                },
            }
        });

    }

    /*
     ******************************************************************************************************************************************************
     *
     * Confirm
     *
     ******************************************************************************************************************************************************
     */
    confirmBox(confirmParams, postParams, uf, pf) {
        $.confirm({
            theme: 'material', // 'material', 'bootstrap'
            type: 'orange',
            icon: 'fas fa-exclamation-triangle',
            //animation: 'top',
            title: confirmParams.title,
            content: confirmParams.message,
            buttons: {
                confirm: {
                    text: '<i class="fas fa-thumbs-up"></i> ' + confirmParams.btnConfirm,
                    btnClass: 'btn btn-success',
                    action: function () {

                        uf.ajaxPost(uf, pf, postParams);
                    }
                },
                cancel: {
                    text: '<i class="fas fa-times"></i> ' + confirmParams.btnDismiss,
                    btnClass: 'btn btn-danger',
                    action: function () {}
                },
            }
        });

    }


    /*
     ******************************************************************************************************************************************************
     *
     * Dialog box per selezione immagini nella gallery
     *
     ******************************************************************************************************************************************************
     */
    dialogGalleryBox(dialogParams, postParams, uf, pf, ac, te) {
        $.confirm({
            theme: 'material', // 'material', 'bootstrap'
            type: 'orange',
            //icon: 'fas fa-exclamation-triangle',
            //animation: 'top',
            title: dialogParams.title,
            //containerFluid: true,
            boxWidth: '80%',
            //draggable: true,
            //dragWindowBorder: true,
            useBootstrap: false,
            content: function () {
                var self = this;

                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    type: postParams.type,
                    url: postParams.uri,
                    data: postParams.data,
                    success: function (response) {
                        self.setContent('<div class="container-fluid">' + response.content + '</div>');
                        loadDropzone('dropzone_images');
                    },
                    error: function (e, textStatus, errorThrown) {
                        console.log('AJAX error: ' + textStatus + ' : ' + errorThrown);
                        console.log('Errore...\n' + JSON.stringify(e));
                    }
                });
            },

            buttons: {
                confirm: {
                    text: '<i class="fas fa-plus-square"></i> ' + dialogParams.btnConfirm,
                    btnClass: 'btn btn-warning',
                    action: function () {


                        //Aggiungo immagini alla gallery
                        var postImagesParams = {
                            'type': 'POST',
                            'uri': postParams.params.images_route,
                            'data': {
                                'images': $('#selected_files').val(),
                                'galleryId': postParams.params.galleryId,
                            },
                            'onSuccess': 'updateTableItemsContainer',
                            'params': ''
                        }

                        //console.log(postImagesParams);
                        uf.ajaxPost(uf, pf, postImagesParams, ac, te);
                    }
                },
                cancel: {
                    text: '<i class="fas fa-times"></i> ' + dialogParams.btnDismiss,
                    btnClass: 'btn btn-danger',
                    action: function () {}
                },
            }
        });

    }




    /*
     ******************************************************************************************************************************************************
     *
     * DateTimepicker
     *
     ******************************************************************************************************************************************************
     */

    formatDate(time, format) {
        var t = new Date(time);
        var tf = function (i) {
            return (i < 10 ? '0' : '') + i
        };
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
            switch (a) {
                case 'yyyy':
                    return tf(t.getFullYear());
                    break;
                case 'MM':
                    return tf(t.getMonth() + 1);
                    break;
                case 'mm':
                    return tf(t.getMinutes());
                    break;
                case 'dd':
                    return tf(t.getDate());
                    break;
                case 'HH':
                    return tf(t.getHours());
                    break;
                case 'ss':
                    return tf(t.getSeconds());
                    break;
            }
        })
    };


    //minDate and maxDate only accept y/m/d format

    dateTimeRange(idFrom, idTo, startDate) {

        jQuery.datetimepicker.setLocale('it');

        var configs = {
            dateFormat: 'd/m/Y',
            timeFormat: 'H:m',
            minTime: '09:00',
            maxTime: '20:00',
            step: 30,
        }
        //var mindate = startDate == null ? null : startDate;

        /*
        $('#' + idFrom).datetimepicker({
            format: dateFormat,
            startDate: mindate,
            inline:false,
            minDate:mindate,

        });
        */
        jQuery('#' + idFrom).datetimepicker({
            format: configs.dateFormat + ' ' + configs.timeFormat,
            minTime: configs.minTime,
            maxTime: configs.maxTime,
            step: configs.step,
            onSelectDate: function (ct) {


                //jQuery('#hid-' + idTo).val() ? jQuery('#' + idTo).val() : false
                //alert(jQuery('#' + idFrom).val());
                this.setOptions({
                    maxDate: jQuery('#' + idTo).val() ? jQuery('#' + idTo).val() : false
                })
            },
            timepicker: true,
        });
        jQuery('#' + idTo).datetimepicker({
            format: configs.dateFormat + ' ' + configs.timeFormat,
            minTime: configs.minTime,
            maxTime: configs.maxTime,
            step: configs.step,
            onSelectDate: function (ct) {

                var startTime = jQuery('#' + idFrom).val();
                var from = new Date(startTime);
                var fromDate = from.getDate() + '/' + from.getMonth() + '/' + from.getFullYear();

                var m = ct.getMonth();
                if (m < 10) {
                    m = '0' + m;
                }
                var toDate = ct.getDate() + '/' + m + '/' + ct.getFullYear();

                //var endTime = new Date(toDate);
                /*
                                if( fromDate < toDate){
                                    alert("fromDate time is lesser");
                                 }
                                 else {
                                    alert("toDate time is lesser");
                                 }
                                 */
                console.log('startTime: ' + startTime);
                console.log('fromDate: ' + from.getMonth());
                console.log('toDate: ' + toDate);


                this.setOptions({
                    minDate: jQuery('#' + idFrom).val() ? jQuery('#' + idFrom).val() : false
                })
            },
            timepicker: true,
        });

    };


    dateRange(idFrom, idTo) {

        jQuery.datetimepicker.setLocale('it');

        var configs = {
            dateFormat: 'd/m/Y',
        }

        jQuery('#' + idFrom).datetimepicker({
            format: configs.dateFormat,
            onSelectDate: function (ct) {

                var from = jQuery('#' + idFrom).val();

                //minDate and maxDate only accept y/m/d format
                var f = moment().format(from);
                var fromValue = moment(f, 'D/MM/YYYY').format('YYYY/MM/D');

                jQuery('#' + idTo).datetimepicker({
                    minDate: fromValue,
                });
                jQuery('#' + idTo).val(f);

            },
            timepicker: false,
        });
        jQuery('#' + idTo).datetimepicker({
            format: configs.dateFormat,
            onShow: function (ct) {

                var from = jQuery('#' + idFrom).val() ? jQuery('#' + idFrom).val() : false
                if (!from == false) {

                    //minDate and maxDate only accept y/m/d format
                    var f = moment().format(from);
                    var fromValue = moment(f, 'D/MM/YYYY').format('YYYY/MM/D');

                    this.setOptions({
                        minDate: fromValue,
                    })

                }
            },
            timepicker: false,
        });

    };

    myDatePicker(className, minDate, startDate) {


        jQuery.datetimepicker.setLocale('it');
        /*
        var forbiddenDays = $('#forbidden-days').attr('data-value');
        forbiddenDays = forbiddenDays.split(',');
        */
        var configs = {
            dateFormat: 'd/m/Y',
            minDate: (typeof (minDate) != "undefined" && minDate !== null) ? minDate : 0,
            startDate: (typeof (startDate) != "undefined" && startDate !== null) ? startDate : 0,
            //forbiddenDays: forbiddenDays,
        }

        jQuery('.' + className).datetimepicker({
            format: configs.dateFormat,
            startDate: configs.startDate,
            minDate: configs.minDate,
            timepicker: false,
            //disabledDates: configs.forbiddenDays,
            formatDate: 'Y-m-d',
        });

    };

    myTimePicker(className) {

        jQuery.datetimepicker.setLocale('it');
        //var forbiddenDays = $('#forbidden-days').attr('data-value');
        //forbiddenDays = forbiddenDays.split(',');

        //console.log(forbiddenDays);

        var configs = {
            formatTime: 'H:i',
            minTime: '08:00',
            maxTime: false,
            allowBlank: true,
            defaultTime: false,
            hours12: false,
            step: 30,
        }

        jQuery('.' + className).datetimepicker({
            datepicker: false,
            format: configs.formatTime,
            formatTime: configs.formatTime,
            minTime: configs.minTime,
            maxTime: configs.maxTime,
            step: configs.step,
            allowBlank: configs.allowBlank,
            defaultTime: configs.defaultTime,
            hours12: configs.hours12,
            /*
            allowTimes: [
                '12:00', '13:00', '15:00',
                '17:00', '17:05', '17:20', '19:00', '20:00'
            ],
            */
            //disabledDates: configs.forbiddenDays,
        });

    };


    myTimePickerRange(className) {


        var configs = {
            timeFormat: 'H:m',
            minTime: '09:00',
            maxTime: '20:00',
            step: 30,
        }

        jQuery('.' + className).datetimepicker({

            format: configs.timeFormat,
            step: configs.step,
            minTime: configs.minTime,
            maxTime: configs.maxTime,

            onShow: function (ct) {
                this.setOptions({
                    maxTime: jQuery('.to-time').val() ? jQuery('.to-time').val() : false
                })
            },
            datepicker: false
        });

        jQuery('.from-time').datetimepicker({

            format: configs.timeFormat,
            step: configs.step,
            minTime: configs.minTime,
            maxTime: configs.maxTime,

            onShow: function (ct) {
                this.setOptions({
                    minDate: jQuery('.from-time').val() ? jQuery('.from-time').val() : false
                })
            },
            datepicker: false
        });
    };


    /*
     ******************************************************************************************************************************************************
     *
     * Datatable
     *
     ******************************************************************************************************************************************************
     */


    dataTable(className, uf = null, pf = null) {



        //Setting defaults
        $.extend(true, $.fn.dataTable.defaults, {

            'order': [
                //Numero della colonna dell'ordinamento di default e direzione
                $('.' + className).attr('data-orderBy'),
                $('.' + className).attr('data-orderDirection')
                //3, 'desc'
            ],

            columnDefs: [{
                    targets: 'no-sort',
                    orderable: false
                },
                {
                    targets: 'hide',
                    visible: false,
                    searchable: true,
                },
            ],

            rowReorder: {
                selector: 'th.orderme'
            },

            pageLength: 50,

            //'pageLength': $('.table-paginate').attr('data-pageLength'), //Dà errori su prev e next
            /*
            //Li prende in automatico se specificato l'attributo data-ordering, data-searching etc nella tabella
            ordering: $('.table-paginate').attr('data-ordering'), //true/false
            searching: $('.table-paginate').attr('data-search'), //true/false
            */
            info: true,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
            },
            //Non sente parametri
            //lengthChange: $('.table-paginate').attr('data-lengthChange'), //Visualizza/nasconde dropdown n° record da mostrare
        });


        var dtTable = $('.' + className).DataTable();

        dtTable.on('row-reorder', function (e, diff, edit) {

            var items = [];

            var route = $('.' + className).attr('data-route');
            var postSuccessFunction = $('.' + className).attr('data-success');
            var rows = e.target.rows;

            /*
            //Inserisco nell'array la nuova posizione degli elementi cambiati
            for (var i = 0, ien = diff.length; i < ien; i++) {
                //var rowData = dtTable.row(diff[i].node).data();
                var itemId = dtTable.row(diff[i].node).id();

                items.push({itemid: itemId, oldPosition: diff[i].oldData, newPosition: diff[i].newData});
            }
            */

            //Inserisco nell'array la nuova posizione di tutti gli elementi
            for (var i = 1; i < rows.length; i++) {
                var itemId = dtTable.row(rows[i]).id();
                items.push({
                    itemid: itemId,
                    newPosition: i
                });

            }

            //console.log(items);

            /*
            $('.' + className).DataTable({
                'destroy': true, //use for reinitialize datatable
             });
*/
            var postParams = {
                'type': 'POST',
                'uri': route,
                'data': {
                    'items': items,
                },
                'onSuccess': postSuccessFunction,
                'params': '',
            }

            uf.ajaxPost(uf, pf, postParams);

        });
    };


    /*
     ******************************************************************************************************************************************************
     *
     * Bootstrap multiselect
     *
     ******************************************************************************************************************************************************
     */
    bsmultiselect(item) {
        //https://github.com/DashboardCode/BsMultiSelect
        $("." + item + ", select[multiple='multiple']").bsMultiSelect()
    };

    /*
     ******************************************************************************************************************************************************
     * DELETE PHISYCALLY COVER IMAGES
     ******************************************************************************************************************************************************
     */

    deleteCoverImage(obj, uf, pf) {

        var id = $(obj).attr('data-id');
        var route = $(obj).attr('data-route');
        var filename = $(obj).attr('data-filename');
        var containerDiv = $(obj).attr('data-container-div');
        var inputId = $(obj).attr('data-input-id');
        var fieldId = $(obj).attr('data-field-id');
        var imageholder = $(obj).attr('data-image-holder');

        var confirmParams = {
            'title': $(obj).attr('data-confirm-title'),
            'message': $(obj).attr('data-confirm-message'),
            'btnConfirm': $(obj).attr('data-btn-confirm'),
            'btnDismiss': $(obj).attr('data-btn-dismiss'),
        }

        var postParams = {
            'type': 'POST',
            'uri': route,
            'data': {
                'id': id,
                'filename': filename,
                'containerDiv': containerDiv,
                'inputId': inputId,
                'fieldId': fieldId,
                'imageholder': imageholder
            },
            'onSuccess': 'updateCoverContainer',
            'params': '',
        }

        //console.log(postParams);

        uf.confirmBox(confirmParams, postParams, uf, pf);

    };

    /*
     ******************************************************************************************************************************************************
     * OPEN MEDIA WINDOW
     ******************************************************************************************************************************************************
     */
    openMediaWindow(obj) {

        var hiddenInput = $(obj).attr('data-fieldname');

        window.open('/file-manager/fm-button?hiddenInput=' + hiddenInput, 'fm', 'width=1400,height=800', obj).focus();
    };

    //Get file extension
    getFileExtension(filename) {
        return filename.split('.').pop().toLowerCase();
    }

    /*
     ******************************************************************************************************************************************************
     * ADD FILE TO PAGE/ARTICLE
     ******************************************************************************************************************************************************
     */
    /*
    Params:
    - path: file path
    - hiddenInput: id of the hidden input containing list of attacched filepaths (strings)
    - successFunction: success function
    */
    addFile(path, hiddenInput, successFunction) {

        var storedFiles = window.opener.$('#' + hiddenInput).val(); //Immagini precedenti
        var files = storedFiles.length > 0 ? storedFiles + ',' + path : path; //Immagini precedenti + immagine selezionata
        window.opener.$('#' + hiddenInput).val(files); //Aggiorno campo hidden

        var route = window.opener.$('#btn_' + hiddenInput).attr('data-route'); //Rotta per il post ajax alla selezione di un file
        var itemId = window.opener.$('#btn_' + hiddenInput).attr('data-item-id'); //Id dell'elemento a cui associare i file
        var model = window.opener.$('#btn_' + hiddenInput).attr('data-model'); //Model a cui associare i file
        var reloadDiv = window.opener.$('#btn_' + hiddenInput).attr('data-reload-div'); //Div da ricaricare con le preview dei files
        var successFunction = window.opener.$('#btn_' + hiddenInput).attr('data-success'); //Eventuale success function
        var locale = window.opener.$('#btn_' + hiddenInput).attr('data-locale'); //Eventuale success function

        var titles = [];
        window.opener.$('.attachment_titles_' + locale).each(function (index, value) {
            titles.push($(this).val());
        });

        //console.log('titoli');
        //console.log(files);
        var postParams = {
            'type': 'POST',
            'uri': route,
            'data': {
                'itemId': itemId,
                'model': model,
                'reloadDiv': reloadDiv,
                'attachments': files,
                'titles': titles,
                'locale': locale,
                'hiddenInput': hiddenInput,
            },
            'onSuccess': successFunction, //'updateGalleryList',
            'params': '',
        }

        uf.ajaxPost(uf, pf, postParams);

    }

    /*
     ******************************************************************************************************************************************************
     * REMOVE FILE TO PAGE/ARTICLE
     ******************************************************************************************************************************************************
     */
    /*
    Params:
    - obj: item to remove
    */
    removeFile(obj) {


        var itemPath = $(obj).attr('data-path'); //Elemento da rimuovere
        var route = $(obj).attr('data-route');
        var model = $(obj).attr('data-model'); //Model a cui associare i file
        var reloadDiv = $(obj).attr('data-reload-div'); //Div da ricaricare con le preview dei files
        var successFunction = $(obj).attr('data-success'); //Eventuale success function
        var locale = $(obj).attr('data-locale'); //Eventuale success function
        var hiddenInput = $(obj).attr('data-hiddenInput'); //Campo hidden che contiene i file selezionati
        var attachments = $('#' + hiddenInput).val();

        var titles = [];
        $('.attachment_titles_' + locale).each(function (index, value) {
            titles.push($(this).val());
        });


        var confirmParams = {
            'title': $(obj).attr('data-confirm-title'),
            'message': $(obj).attr('data-confirm-message'),
            'btnConfirm': $(obj).attr('data-btn-confirm'),
            'btnDismiss': $(obj).attr('data-btn-dismiss'),
        }

        var postParams = {
            'type': 'POST',
            'uri': route,
            'data': {
                'itemPath': itemPath,
                'model': model,
                'reloadDiv': reloadDiv,
                'attachments': attachments,
                'titles': titles,
                'locale': locale,
                'hiddenInput': hiddenInput,
            },
            'onSuccess': successFunction, //'updateGalleryList',
            'params': '',
        }


        //console.log(postParams);

        uf.confirmBox(confirmParams, postParams, uf, pf);
    };


    /*
     ******************************************************************************************************************************************************
     * ADD IMAGE TO WINERY GALLERY -- SPECIFIC FOR TWA!!!
     ******************************************************************************************************************************************************
     */


    addImage(url, fileListContainer, imagetype) {

        var imagetypeId = window.opener.$('#' + fileListContainer).attr('data-imagetype');

        //console.log(fileListContainer);
        var oldImages = window.opener.$('#gallerytype_' + imagetype).val(); //Immagini precedenti
        //var oldImages = window.opener.$('#gallerytype_' + imagetypeId).val(); //Immagini precedenti


        var images = oldImages.length > 0 ? window.opener.$('#gallerytype_' + imagetype).val() + ',' + url : url; //Immagini precedenti + immagine selezionata
        //var images = oldImages.length > 0 ? window.opener.$('#gallerytype_' + imagetypeId).val() + ',' + url : url; //Immagini precedenti + immagine selezionata
        window.opener.$('#gallerytype_' + imagetype).val(images); //Aggiorno campo hidden
        //window.opener.$('#gallerytype_' + imagetypeId).val(images); //Aggiorno campo hidden

        var route = window.opener.$('#btn_' + fileListContainer).attr('data-route');
        var wineryId = window.opener.$('#btn_' + fileListContainer).attr('data-winery-id');

        var postParams = {
            'type': 'POST',
            'uri': route,
            'data': {
                'wineryId': wineryId,
                'images': images,
                'imagetypeId': imagetype
                //'imagetypeId': imagetypeId
            },
            'onSuccess': 'updateGalleryList',
            'params': '',
        }

        uf.ajaxPost(uf, pf, postParams);

    }

    /*
     ******************************************************************************************************************************************************
     * RELOAD ORIGINAL FILE
     ******************************************************************************************************************************************************
     */
    //Ricarico la preview del file originale (usato nel filemanager quando si cambia immagine)
    reloadOriginalFile(obj) {

        var fieldname = $(obj).attr('data-fieldname');
        var originalfile = '\/' + $('#' + fieldname).attr('data-original-filename');

        $('#' + fieldname + '_preview').attr('src', originalfile);
        $('#' + fieldname).val(originalfile);
        $('#' + fieldname + '_delete_btn').removeClass('d-none'); //Rendo visibile btn delete
        $('#' + fieldname + '_reload_btn').addClass('d-none'); //Rendo invisibile btn reload

    };

    /*
     ******************************************************************************************************************************************************
     * REMOVE FILE
     ******************************************************************************************************************************************************
     */
    //Ricarico la preview del file originale (usato nel filemanager quando si cambia immagine)
    clearUploadedFile(obj) {


        var fieldname = $(obj).attr('data-fieldname');
        var originalfile = $('#' + fieldname).attr('data-original-filename');


        $('#' + fieldname + '_preview').attr('src', null);
        $('#' + fieldname).val(null);
        $('#' + fieldname + '_delete_btn').addClass('d-none'); //Rendo invisibile btn delete

        if (typeof (originalfile) != 'undefined' && originalfile !== null) {
            $('#' + fieldname + '_reload_btn').removeClass('d-none'); //Rendo visibile btn reload
        }
    };


    /*
     ******************************************************************************************************************************************************
     *
     * Uploader
     *
     ******************************************************************************************************************************************************
     */
    uploader(item, uploaderParameters, uf, pf) {
        var configOptions = uploaderParameters.options;
        var fileListContainer = uploaderParameters.fileListContainer;
        var filesTemplate = uploaderParameters.filesTemplate;

        var debugParameters = uploaderParameters.debugParameters;
        var debugContainer = debugParameters.debugContainer;
        var debugTemplate = debugParameters.debugTemplate;


        var uri = $('.' + item).attr('data-route');
        /*
        console.log('uri: ' + uri);
        console.log('options:');
        console.log(configOptions);
        */

        $('.' + item).dmUploader({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: uri,
            allowedTypes: "image/*", //Default "*"
            extFilter: ['jpg', 'jpeg', 'png', 'gif'], //Estenioni consentite
            multiple: false,
            configOptions,

            onDragEnter: function () {
                // Happens when dragging something over the DnD area
                this.addClass('active');
            },
            onDragLeave: function () {
                // Happens when dragging something OUT of the DnD area
                this.removeClass('active');
            },
            onInit: function () {
                // Plugin is ready to use
                ui_add_log('Plugin initialized :)', 'info');
            },
            onComplete: function () {
                // All files in the queue are processed (success or error)
                ui_add_log('All pending tranfers finished');
            },
            onNewFile: function (id, file) {
                // When a new file is added using the file selector or the DnD area
                ui_add_log('New file added #' + id);
                ui_multi_add_file(id, file);
                createThumb(id, file);

            },
            onBeforeUpload: function (id) {
                // about tho start uploading a file
                ui_add_log('Starting the upload of #' + id);
                ui_multi_update_file_progress(id, 0, '', true);
                ui_multi_update_file_status(id, 'uploading', 'Uploading...');
            },
            onUploadProgress: function (id, percent) {
                // Updating file progress
                ui_multi_update_file_progress(id, percent);
            },
            onUploadSuccess: function (id, data) {
                //console.log(data);

                // A file was successfully uploaded
                //ui_add_log('Server Response for file #' + id + ': ' + JSON.stringify(data));
                ui_add_log('Upload of file #' + id + ' COMPLETED', 'success');
                ui_multi_update_file_status(id, 'success', 'Upload Complete');
                ui_multi_update_file_progress(id, 100, 'success', false);

                //Assegno il nome del file come data-attr alla preview del file per l'eliminazione
                //console.log($(this).find('img').attr('id'));
            },
            onUploadError: function (id, xhr, status, message) {
                // Happens when an upload error happens
                ui_multi_update_file_status(id, 'danger', message);
                ui_multi_update_file_progress(id, 0, 'danger', false);
            },
            onFallbackMode: function () {
                // When the browser doesn't support this plugin :(
                ui_add_log('Plugin cant be used here, running Fallback callback', 'danger');
            },
            onFileSizeError: function (file) {
                ui_add_log('File \'' + file.name + '\' cannot be added: size excess limit', 'danger');
            },

        });


        /*
         ******************************************************************************************************************************************************
         * REMOVE UPLOADED ITEMS
         ******************************************************************************************************************************************************
         */
        $(document).on('click', '.uploader-delete-file', function (e) {

            e.preventDefault();
            var filename = $(this).find('img').attr('data-name');
            //console.log($('#' + fileListContainer).attr('data-delete-route'),);

            var confirmParams = {
                'title': $('#' + fileListContainer).attr('data-confirm-title'),
                'message': $('#' + fileListContainer).attr('data-confirm-message'),
                'btnConfirm': $('#' + fileListContainer).attr('data-btn-confirm'),
                'btnDismiss': $('#' + fileListContainer).attr('data-btn-dismiss'),
            }

            var postParams = {
                'type': 'POST',
                'uri': $('#' + fileListContainer).attr('data-delete-route'),
                'data': {
                    'filename': filename,
                },
                'onSuccess': 'updateUploadList',
                'params': '',
            }


            uf.confirmBox(confirmParams, postParams, uf, pf);

        });




        /*
         ******************************************************************************************************************************************************
         * HELPER FUNCTIONS
         ******************************************************************************************************************************************************
         */
        function createThumb(id, file) {
            //Creazione thumbnail preview
            if (typeof FileReader !== "undefined") {
                var reader = new FileReader();
                var img = $('#uploaderFile' + id).find('img');

                //console.log(file);

                reader.onload = function (e) {
                    img.attr('src', e.target.result);
                    img.attr('id', id); //Aggiungo id all'icona remove
                    img.attr('data-name', file.name); //Aggiungo filename all'icona remove
                }
                reader.readAsDataURL(file);
            }
        }


        // Adds an entry to our debug area
        function ui_add_log(message, color) {
            var d = new Date();

            var dateString = (('0' + d.getHours())).slice(-2) + ':' +
                (('0' + d.getMinutes())).slice(-2) + ':' +
                (('0' + d.getSeconds())).slice(-2);

            color = (typeof color === 'undefined' ? 'muted' : color);

            var template = $('#' + debugTemplate).text();
            template = template.replace('%%date%%', dateString);
            template = template.replace('%%message%%', message);
            template = template.replace('%%color%%', color);

            $('#' + debugContainer).find('li.empty').fadeOut(); // remove the 'no messages yet'
            $('#' + debugContainer).prepend(template);
        }

        // Creates a new file and add it to our list
        function ui_multi_add_file(id, file) {
            var template = $('#' + filesTemplate).text();
            console.log(template);
            template = template.replace('%%filename%%', file.name);

            template = $(template);
            template.prop('id', 'uploaderFile' + id);
            template.data('file-id', id);

            $('#' + fileListContainer).find('tr.empty').fadeOut(); // remove the 'no files yet'
            $('#' + fileListContainer).prepend(template);
        }

        // Changes the status messages on our list
        function ui_multi_update_file_status(id, status, message) {
            $('#uploaderFile' + id).find('span').html(message).prop('class', 'status text-' + status);
        }

        // Updates a file progress, depending on the parameters it may animate it or change the color.
        function ui_multi_update_file_progress(id, percent, color, active) {
            color = (typeof color === 'undefined' ? false : color);
            active = (typeof active === 'undefined' ? true : active);

            var bar = $('#uploaderFile' + id).find('div.progress-bar');

            bar.width(percent + '%').attr('aria-valuenow', percent);
            bar.toggleClass('progress-bar-striped progress-bar-animated', active);

            if (percent === 0) {
                bar.html('');
            } else {
                bar.html(percent + '%');
            }

            if (color !== false) {
                bar.removeClass('bg-success bg-info bg-warning bg-danger');
                bar.addClass('bg-' + color);
            }
        }


    }

    /*
    |--------------------------------------------------------------------------
    |
    | MODALI
    |
    |--------------------------------------------------------------------------
    */

    //Chiamo la funzione per recuperare il contenuto da inserire nella modale
    callModal(uf, pf, params) {

        var postParams = {
            'type': 'POST',
            'uri': params.route,
            'data': params.data,
            'onSuccess': params.onSuccess,
            'params': params.params,
            'ismodal': true,

        };

        uf.ajaxPost(uf, pf, postParams);

    }


    //Mostro modale
    showModal(response, uf, params) {

        var modalId = params.modalId;

        $('#' + modalId + ' .modal-body').html(response.view);
        //$('#' + modalId + ' .' + params.containerClass).html(response.view);
        $('#' + modalId).modal({
            backdrop: 'static' //Chiudo la modal solo cliccando su close
        });

        if (params.params.editorConfig !== '') {
            te.loadTinyMCEEditor();
            te.mceInit();
        }

        if (params.params.datepickerClass !== '') {
            uf.myDatePicker(params.params.datepickerClass);
        }
    }



}


export default utilitiesFunctions // ES6
