/*
|=================================================================================================================================
|
| FUNZIONI UTILI PER LE FORMS
|
|=================================================================================================================================
*/


class formUtilities {
    //Funzione che abilita campi compilabili, li svuota e aggiunge dei bordi
    //Passo array di classi, per ognuna delle qualli scorro i campi ad essa associati e li abilito
    //Params:
    //bool enable: se true, abilito, altrimenti disabilito
    //array classi: array di classi da controllare
    enableDisabelFields(enable, classi) {

        $.each(classi, function (index, value) {
            $('.' + value).each(function (item) {
                if (enable) {
                    $(this).prop('disabled', false);
                    $(this).addClass('border border-success');
                    $(this).val('');
                } else {
                    $(this).prop('disabled', true);
                    $(this).removeClass('border border-success');
                    $(this).val('');
                }
            });
        });
    }


    //Controllo che il campo sia numerico
    checkIsNumeric(fieldId) {

        var success = $.isNumeric($('#' + fieldId).val());

        if (!success) {
            setTimeout(function () {
                $('#' + fieldId).focus();
            }, 100);
            $('#' + fieldId).val('').attr('placeholder', 'Inserire valore numerico');
            return true;
        }
        return false;
    }

    //Valida una form
    validateForm(formId, cssRules, rules, messages) {

        var form = $('#' + formId);
        var hasTab = $('#' + formId).attr('data-hastab');

        //console.log(hasTab);

        $.validator.addMethod('valueNotEquals', function (value, element, arg) {
            return arg !== value;
        }, 'Value must not equal arg.');

       $.validator.addClassRules(cssRules);

        form.validate({
            debug: true,
            ignore: '.ignore', //Durante la validazione ignora i campi con classe "ignore"
            errorElement: 'span',
            errorClass: 'error',
            focusInvalid: true,
            onfocusout: false,
            rules: rules,
            messages: messages,
            /*
            errorPlacement: function () {},
            */
            /*
            submitHandler: function() {
                alert('Successfully saved!');
            },
            */
            invalidHandler: function (form, validator) {
                setTimeout(function () {
                    var errors = validator.numberOfInvalids();
                    var firstError = validator.errorList[0].element.id;

                    if (errors) {
                        //validator.errorList[0].element.focus();

                        $('html,body').animate({
                            scrollTop: $('input[id="' + firstError + '"]').offset().top - 100
                        }, 500);
                        $('input[id="' + firstError + '"]').focus()

                        if (hasTab == 'true') {
                            $('.nav-tabs a small.required').remove();
                            var validatePane = $('.tab-content.tab-validate .tab-pane:has(input.error)').each(function () {
                                var id = $(this).attr('id');
                                $('.nav-tabs').find('a[href^="#' + id + '"]').append(' <small class="required">***</small>');
                            });
                        }

                        return false;

                    }
                });
            },
        });



        /*
        //Regole per hiddenRecaptcha
        hiddenRecaptcha: {
            required: function() {
                if (grecaptcha.getResponse() == '') {
                    return true;
                } else {
                    return false;
                }
            }
        }
        */

        return form.valid();

    }



    //Invio form
    sendForm(formId, uf, pf) {

        var route = $('#' + formId).attr('data-route');
        var successFunction = $('#' + formId).attr('data-success');
        var dataRoute = $('#' + formId).attr('data-route'); //Spedisco la form in ajax o no. Default false

        /*
        var pageSize = $('#' + formId).attr('data-pagesize');
        var pages = $('#' + formId).attr('data-pages');
        var currentPage = $('#' + formId).attr('data-currentpage');
        */
        var isAjax = (dataRoute == '' || typeof (dataRoute) == 'undefined') ? false : true;

        //console.log($('#' + formId).serializeArray());
        //console.log(isAjax);

        if (isAjax == true) {

            var postParams = {
                'type': 'POST',
                'uri': route,
                'data': {
                    'formValues': $('#' + formId).serializeArray(),
                },
                'onSuccess': successFunction, //'submittedForm',
                'params': {
                    'formid': formId,
                }
            }

            //console.log(postParams);

            uf.ajaxPost(uf, pf, postParams);
        } else {
            $('#' + formId)[0].submit();
        }
    }

}


export default formUtilities; //ES6
