class postSuccessFunctions {


    //Chiamata dinamica della funzione di successo sull'ajaxpost
    executeSuccess(successFunction, response, uf, ac, te) {
        //console.log(successFunction);
        this[successFunction](response, uf, ac, te);
    }


    /*
     *************************************************************************
     * FUNCTIONS
     *************************************************************************
     */

    //Users
    updateUsersList(response, uf) {
        $('#users_container').html(response.view);
    }

    //Templates
    updateTemplatesList(response, uf) {
        $('#templates_container').html(response.view);
    }

    //Positions
    updatePositionsList(response, uf) {
        $('#positions_container').html(response.view);
    }

    //Blocks
    updateBlocksList(response, uf) {
        $('#blocks_container').html(response.view);
    }

    //Blocks - Carico record nella select di creazione/modifica blocco
    fillModelContent(response, uf) {
        $('#records').html(response.view);
    }

    //Menus
    updateMenusList(response, uf) {
        $('#menus_container').html(response.view);
    }

    //Pages
    updatePagesList(response, uf) {
        $('#pages_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    //Categories
    updateCategoriesList(response, uf) {
        $('#categories_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);

    }

    //Articles
    updateArticlesList(response, uf) {
        $('#articles_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    //Articles - filter by year
    articleFilterByYear(response, uf) {
        console.log(response);

        $('#year_container').html(response.view);

    }

    //Iscrizione mailing list
    updateNewsletterForm(response, uf) {
        //console.log(response);
        $('.newsletter-response').removeClass('collapse').addClass('alert-' + response.class).html(response.responseText);

        if (response.class == 'success') {
            $('#' + response.formid)[0].reset();
        }
    }

    //Tags
    updateTagsList(response, uf) {
        $('#tags_container').html(response.view);
    }

    /*
     *************************************************************************
     * CONTACTS FORM
     *************************************************************************
     */
    updateContactsForm(response, uf) {
        //console.log(response);
        $('.form-response').removeClass('collapse').html(response.responseText);
    }

    updateProfessionList(response, uf) {
        $('#profession_id').html(response.view);
    }

    /*
     *************************************************************************
     * MAILLING LIST
     *************************************************************************
     */
    //Mailinglist
    updateSubscribersList(response, uf) {
        $('#subscribers_container').html(response.view);
    }

    updateMailingGroupsList(response, uf) {
        $('#mlgroups_container').html(response.view);
    }


    updateMailinglistList(response, uf) {
        $('#mailinglists_container').html(response.view);
    }

    //Mailinglist
    updateSubscribersList(response, uf) {
        $('#subscribers_container').html(response.view);
    }

    //Iscrizione mailing list
    updateNewsletterForm(response, uf) {
        //console.log(response);
        $('.newsletter-response').removeClass('collapse').addClass('alert-' + response.class).html(response.responseText);

        if (response.class == 'success') {
            $('#' + response.formid)[0].reset();
        }
    }

    //Cancellazione da mailing list
    updateUnsubscribeForm(response, uf) {
        //console.log(response);
        $('.form-response').removeClass('collapse').addClass('alert-' + response.class).html(response.responseText);

        if (response.class == 'success') {
            $('#' + response.formid)[0].reset();
        }
    }


    /*
     ******************************************************************************************************************************************************
     * MEDIA
     ******************************************************************************************************************************************************
     */

    //NON PIU' USATI
    /*
    //Mostro i file della cartella
    updateImagesGrid(response, uf) {
        $('#' + response.container).html(response.view);
        $('#selected_files').val(response.selectedImages);
        loadDropzone('dropzone_images');
    }

    //Gallery - Carico record nella select di creazione/modifica gallery
    getRelatedRecords(response, uf) {
        $('#record_id').html(response.view);
    }

    //Gallery - Ricarico elenco files dopo aggiunta da modale
    updateTableItemsContainer(response, uf, ac, te) {
        //console.log(response.images);
        $('#table_items_container').html(response.view);
    }
    */

    //Aggiorno lista di allegati selezionati dalla modale di vendor/file-filemanager
    updateAttachmentList(response, uf) {
        var str = null;
        $.each(response.files, function (i, e) {
            str = str !== null ? str + ',' + e.path : e.path;
        });

        window.opener.$('#' + response.hiddenInput).val(str); //Aggiorno il campo hidden con i file selezionati senza eventuali doppioni
        window.opener.$('#' + response.reloadDiv).html(response.view);
        window.close();

    }

    //Aggiorno lista di allegati dopo l'eliminazione di un allegato
    reloadAttachmentList(response, uf) {
        var str = null;

        $.each(response.files, function (i, e) {
            str = str !== null ? str + ',' + e.path : e.path;
        });

        console.log(response.hiddenInput);
        console.log(response.reloadDiv);

        $('#' + response.hiddenInput).val(str); //Aggiorno il campo hidden con i file selezionati senza eventuali doppioni
        $('#' + response.reloadDiv).html(response.view);

    }




    /*
     ******************************************************************************************************************************************************
     * UPLOADER
     ******************************************************************************************************************************************************
     */
    /*
     updateUploadList(response, uf) {
         console.log(response.urls);
         $('.' + response.divtoreload).html(response.view);
     }
     */

    updateCoverContainer(response, uf) {
        $('.' + response.divtoreload + ' .img').html(response.replaceHtml);
        $('#' + response.inputId).val('');
    }

    /*
     ******************************************************************************************************************************************************
     * DATATABLE
     ******************************************************************************************************************************************************
     */

    datatableUpdateSort(response, uf) {
        //console.log(response);
    }


    /*
     ******************************************************************************************************************************************************
     *
     *
     * TWA
     *
     *
     ******************************************************************************************************************************************************
     */

    //Mostro la cantina in evidenza nella home
    showWineryHighLight(response, uf, ac, te) {
        //console.log(response.images);
        $('#wineryContainer').html(response.view);
    }

    //Filtro cantine per zona
    filterWineries(response, uf, ac, te) {
        //console.log(response.images);

        $('#ax-wineries-container').html(response.view);

        var id = response.zoneId;

        $('.zone-container').addClass('imageDesaturate');

        $('.zone-container').each(function (index, value) {
            if (id === $(this).attr('data-zone-id')) {
                $('#zone_' + id + '_container').removeClass('imageDesaturate');
            }
        });

    }

    //Esito richiesta prenotazione visita
    updateBookingForm(response, uf) {
        //console.log(response);
        $('#bookingModal .modal-body').html(response.responseText);
    }

    //Esito richiesta preventivo
    updatePriceQuotationForm(response, uf) {
        $('#priceQuotationModal .modal-body').html(response.responseText);
    }


    /*
     ******************************************************************************************************************************************************
     * TWA ADMIN
     ******************************************************************************************************************************************************
     */
    updateWineriesList(response, uf) {
        $('#wineries_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateZonesList(response, uf) {
        $('#zones_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateArchitectsList(response, uf) {
        $('#architects_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateActivitiesList(response, uf) {
        $('#activities_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateToursList(response, uf) {
        $('#tours_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateStagesList(response, uf) {
        $('#stages_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateStagedetailsList(response, uf) {
        $('#stagedetails_container').html(response.view);
        uf.dataTable('table-paginate', uf, this);
    }

    updateGalleryList(response, uf) {
        //console.log(response);
        window.opener.$('#' + response.container).html(response.view);
        window.close();

    }

}


export default postSuccessFunctions // ES6
